import { format, parse } from 'date-fns';
import {
  isMeDetailsFallback,
  useMeDetailsQuery,
} from 'legoland-sdk/dist/experimental';

const defaultDateFormat = 'dd/MM/yyyy';
const defaultTimeFormat = 'HH:mm';

/** Options to configure the user date format. */
export type UseUserDateFormatOptions = {
  /** Whether to include the time part. */
  withTime?: boolean;
};

/**
 * Hook to get the user's date format.
 *
 * @example
 *
 * ```ts
 * const userDateFormat = useUserDateFormat();
 * // 'dd/MM/yyyy' (if the user's date format is 'DD/MM/YYYY')
 * ```
 *
 * @param options - Options to configure the user date format.
 * @returns The user's date format in the form of a date-fns format string.
 */
export function useUserDateFormat(options: UseUserDateFormatOptions = {}) {
  const { withTime } = options;
  const { data } = useMeDetailsQuery();
  let dateFormat = defaultDateFormat;
  let timeFormat = defaultTimeFormat;
  if (
    data?.meDetails &&
    !isMeDetailsFallback(data.meDetails) &&
    data.meDetails.settings
  ) {
    dateFormat =
      data.meDetails.settings.dateFormat === 'MMDDYYYY'
        ? 'MM/dd/yyyy'
        : 'dd/MM/yyyy';
    timeFormat =
      data.meDetails.settings.clockFormat === '12' ? 'hh:mm aaaa' : 'HH:mm';
  }
  return dateFormat + (withTime ? ` ${timeFormat}` : '');
}

/**
 * Hook to format date and time according to the user's settings.
 *
 * @example
 *
 * ```ts
 * const formatDate = useFormatUserDate();
 * const formattedDate = formatDate('2023-10-05');
 * // '05/10/2023' (if the user's date format is 'DD/MM/YYYY')
 * ```
 *
 * @param options - Options to configure the user date format.
 * @returns A function that formats a given date string or Date object.
 *
 *   The returned function takes a date as a string or Date object and formats it
 *   according to the user's date format. If the input date is a string, it
 *   attempts to parse it into a Date object. If parsing fails, it returns
 *   'Invalid date'.
 */
export function useFormatUserDate(options: UseUserDateFormatOptions = {}) {
  const userDateFormat = useUserDateFormat(options);

  return function formatDate(date: string | Date) {
    if (typeof date === 'string') {
      try {
        date = parseDate(date);
      } catch (error) {
        console.error(error);
        return 'Invalid date';
      }
    }
    return format(date, userDateFormat);
  };
}

const highPrecisionDateTimeRegex =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}Z$/;
const isoDateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;

/**
 * Parses a date string and returns a Date object.
 *
 * The function supports three formats:
 *
 * 1. High precision date-time format (e.g., "2023-10-01T12:34:56.789159Z")
 * 2. ISO date-time format (e.g., "2023-10-01T12:34:56.789Z")
 * 3. ISO date format (e.g., "2023-10-01")
 *
 * @param date - The date string to parse.
 * @returns A Date object representing the parsed date.
 * @throws Will throw an error if the date format is unsupported.
 */
function parseDate(date: string) {
  if (highPrecisionDateTimeRegex.test(date)) {
    return new Date(date.slice(0, -5) + 'Z');
  }

  if (isoDateTimeRegex.test(date)) {
    return new Date(date);
  }

  if (isoDateRegex.test(date)) {
    return parse(date, 'yyyy-MM-dd', new Date());
  }

  throw new Error('Invalid date format');
}
