import { utc } from 'moment';
import { CommunicationItem, CommunicationType } from '../../communicationTypes';
import { CommunicationFormValues } from './communicationValidationSchema';

export function convertCommunicationFormValuesToItem({
  isReleaseNotes,
  ...values
}: CommunicationFormValues) {
  return {
    ...values,
    date: utc(values.date).startOf('day').toISOString().slice(0, 10),
    type: isReleaseNotes
      ? CommunicationType.ReleaseNotes
      : CommunicationType.EmailOnly,
  } as CommunicationItem;
}
