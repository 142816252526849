import { TypedJsonResponse } from 'legoland-sdk/dist/experimental';
import {
  MutationFunction,
  QueryKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useToasts } from 'tombac';
import { useAppApiClient } from './AppApiClientContext';
import { AppApiClient } from './createAppApiClient';

// https://stackoverflow.com/questions/48011353/how-to-unwrap-type-of-a-promise
type PromiseType<T> = T extends PromiseLike<infer U> ? PromiseType<U> : T;

type TypedJsonResponseType<T> = T extends TypedJsonResponse<infer U> ? U : T;

export type ClientReturnType<T extends keyof AppApiClient> = PromiseType<
  ReturnType<AppApiClient[T]>
>;

export type ClientJsonType<T extends keyof AppApiClient> =
  TypedJsonResponseType<ClientReturnType<T>>;

export type ClientOptions<T extends keyof AppApiClient> = Parameters<
  AppApiClient[T]
>[0];

type ClientQueryHookDataType<
  T extends keyof AppApiClient,
  PropName extends string,
> = { [key in PropName]: ClientJsonType<T> } & {
  response: ClientReturnType<T>;
};

export type ClientQueryHook<
  T extends keyof AppApiClient,
  PropName extends string,
> = (
  options: ClientOptions<T>,
  queryOptions?: UseQueryOptions<ClientQueryHookDataType<T, PropName>>,
) => UseQueryResult<ClientQueryHookDataType<T, PropName>>;

export type ClientQueryHookOptionless<
  T extends keyof AppApiClient,
  PropName extends string,
> = (
  queryOptions?: UseQueryOptions<ClientQueryHookDataType<T, PropName>>,
) => UseQueryResult<ClientQueryHookDataType<T, PropName>>;

export async function getQueryResult<T>(
  response: TypedJsonResponse<T>,
): Promise<T | null>;
export async function getQueryResult<T>(response: Response): Promise<T | null>;
export async function getQueryResult<T>(response: Response): Promise<T | null> {
  if (response.ok) {
    return (await response.json()) as T;
  } else if (response.status === 401 || response.status === 404) {
    return null;
  }
  throw response;
}

export function createMutationResponseHandler<
  MutateFn extends (...args: any) => Promise<Response>,
  FetchFn extends (...args: any) => Promise<Response> = undefined,
  R = TypedJsonResponseType<
    PromiseType<ReturnType<FetchFn extends undefined ? MutateFn : FetchFn>>
  >,
>(
  mutateFn: MutateFn,
  fetchFn?: FetchFn,
): MutationFunction<R | null, Parameters<MutateFn>[0]> {
  return async (parameters: Parameters<MutateFn>[0]): Promise<R | null> => {
    let response = await mutateFn(parameters);
    if (!response.ok) {
      throw response;
    }
    const locationHeader = response.headers.get('location');
    if (fetchFn && response.status === 201 && locationHeader) {
      response = await fetchFn({ uri: locationHeader });
    }
    try {
      return (await response.json()) as R;
    } catch (error) {
      return null;
    }
  };
}

/**
 * Create mutation for the specified function from app API client.
 *
 * This hook is designed to be used in your mutation hooks to speed up creation
 * of simple mutations.
 *
 * ```ts
 * export function useUpdateUserMutation() {
 *   const queryClient = useQueryClient();
 *   return useApiMutation('updateUser', {
 *     queryOptions: {
 *       onSettled() {
 *         queryClient.invalidateQueries(['users']);
 *       },
 *     },
 *   });
 * }
 * ```
 *
 * @param apiFnName Name of the function in appApiClient.
 * @param options API mutation options.
 * @returns React Query's useMutation hook result.
 */
export function useApiMutation<
  ApiFnName extends keyof AppApiClient,
  FetchFnName extends keyof AppApiClient = undefined,
>(
  apiFnName: ApiFnName,
  options?: {
    fetchFnName?: FetchFnName;
    mutationOptions?: UseMutationOptions<
      ClientJsonType<FetchFnName extends undefined ? ApiFnName : FetchFnName>,
      unknown,
      Parameters<AppApiClient[ApiFnName]>[0],
      unknown
    >;
  },
) {
  const apiClient = useAppApiClient();
  const apiFn: AppApiClient[ApiFnName] = apiClient[apiFnName];
  const responseHandler = createMutationResponseHandler(
    apiFn,
    options?.fetchFnName && apiClient[options?.fetchFnName],
  );
  return useMutation(
    responseHandler,
    options?.mutationOptions as any,
  ) as UseMutationResult<
    ClientJsonType<FetchFnName extends undefined ? ApiFnName : FetchFnName>,
    Response,
    Parameters<AppApiClient[ApiFnName]>[0],
    unknown
  >;
}

type ApiQueryHook<F extends keyof AppApiClient> = (
  params?: Parameters<AppApiClient[F]>[0],
  queryOptions?: UseQueryOptions<ClientJsonType<F>>,
) => UseQueryResult<ClientJsonType<F>>;

/**
 * Create a query hook for the specified function from the app API client.
 *
 * ```ts
 * const useUserList = createApiQueryHook(['users'], 'getUserList');
 *
 * const UserList: FC = () => {
 *   const { data: users } = useUserList();
 *   return (
 *    <>
 *      {users?.map((user) => <li>{user.email}</li>)}
 *     </>
 *   )
 * };
 * ```
 *
 * @param queryKey A query key or a function returning a query key. In case of
 *   the function, it receives query parameters as an argument.
 * @param apiFunctionName Name of the function in the app API client.
 * @returns React Query's useQuery result.
 */
export function createApiQueryHook<F extends keyof AppApiClient>(
  queryKey: QueryKey | ((params: Parameters<AppApiClient[F]>[0]) => QueryKey),
  apiFunctionName: F,
): ApiQueryHook<F> {
  return (params, queryOptions) => {
    const apiClient = useAppApiClient();
    return useQuery(
      typeof queryKey === 'function' ? queryKey(params as any) : queryKey,
      async () => {
        const response = await apiClient[apiFunctionName](params as any);
        const data = await getQueryResult(response);
        return data;
      },
      queryOptions,
    );
  };
}

type ApiErrorInfo = {
  message: string;
};

/**
 * Resolves the error message from an API response or unknown error.
 *
 * @param error - The error object, which can be a Response or unknown.
 * @param options - Optional configuration for the error resolution.
 * @param options.defaultMessage - The default error message to use if no
 *   specific message is found.
 * @param options.logErrors - Whether to log errors to the console.
 * @returns The resolved error message.
 */
export async function resolveApiErrorMessage(
  error: Response | unknown,
  {
    defaultMessage = 'Oops! Something went wrong!',
    logErrors = false,
  }: { defaultMessage?: string; logErrors?: boolean } = {},
) {
  let message = defaultMessage;
  if (error instanceof Response) {
    try {
      const info = (await error.json()) as ApiErrorInfo | object;
      if ('message' in info) {
        message = info.message;
      } else {
        logErrors && console.error(error);
      }
    } catch (e) {
      logErrors && console.error(error);
      logErrors && console.error(e);
    }
  } else {
    logErrors && console.error(error);
  }
  return message;
}

/**
 * Custom hook that displays an error toast message for API errors.
 *
 * @param error - The API error response or an unknown error.
 * @param defaultMessage - The default error message to display if the API error
 *   message cannot be resolved.
 * @returns A function that can be called to display an error toast.
 */
export function useApiErrorToast() {
  const { addToast } = useToasts();
  return async (
    error: Response | unknown,
    defaultMessage = 'Oops! Something went wrong!',
  ) => {
    const message = await resolveApiErrorMessage(error, {
      defaultMessage,
      logErrors: true,
    });
    addToast(message, 'danger', { autoDismiss: false, dismissable: true });
  };
}
