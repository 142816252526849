import { useProductsQuery } from 'legoland-sdk/dist/experimental';
import { useMemo } from 'react';

export type CommunicationProduct = {
  id: string;
  displayName: string;
};

const supportedProductIds = ['ctm', 'ja', 'll', 'od', 'ts'];

export function useCommunicationProducts() {
  const query = useProductsQuery();

  const products = useMemo(
    () =>
      query.data
        ? [
            { id: 'll', displayName: 'Move' } as CommunicationProduct,
            ...query.data.products,
          ]
            .filter((product) => supportedProductIds.includes(product.id))
            .sort((a, b) => a.displayName.localeCompare(b.displayName))
        : [],
    [query.data],
  );

  return { products, query };
}
