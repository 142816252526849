import { CollectionDto } from 'app/api/app-api/apiTypes';
import { AppApiPartProps } from 'app/api/app-api/createAppApiClient';
import { UserRole } from 'legoland-sdk/dist/experimental';
import {
  CommunicationEmailRecipients,
  CommunicationItem,
  CommunicationItemPartial,
  CommunicationsQuery,
} from './communicationTypes';

export function communicationsApi({
  baseUrl,
  del,
  get,
  patchJson,
  post,
  postJson,
}: AppApiPartProps) {
  return {
    addCommunication({
      communicationItem,
    }: {
      communicationItem: CommunicationItem;
    }) {
      return postJson(`${baseUrl}/communications`, communicationItem);
    },

    deleteCommunication({
      communicationItemId,
    }: {
      communicationItemId: number;
    }) {
      return del(`${baseUrl}/communications/${communicationItemId}`);
    },

    getCommunicationEmailRecipients({
      communicationItemId,
    }: {
      communicationItemId: number;
    }) {
      return get<CommunicationEmailRecipients>(
        `${baseUrl}/communications/${communicationItemId}/emails/recipients`,
      );
    },

    getCommunicationItem({
      communicationItemId,
    }: {
      communicationItemId: number;
    }) {
      return get<CommunicationItem>(
        `${baseUrl}/communications/${communicationItemId}`,
      );
    },

    getCommunicationList(query: CommunicationsQuery = {}) {
      const searchParams = new URLSearchParams();
      searchParams.append('size', '9999');

      const arrayFilters = ['products', 'roles', 'types'] as const;

      for (const filter of arrayFilters) {
        for (const value of query.filters[filter] ?? []) {
          searchParams.append(filter, value);
        }
      }

      const url = new URL(`${baseUrl}/communications`);
      url.search = searchParams.toString();

      return get<CollectionDto<CommunicationItem>>(url.toString());
    },

    sendCommunicationEmail({
      communicationItemId,
    }: {
      communicationItemId: number;
    }) {
      return post(`${baseUrl}/communications/${communicationItemId}/emails`);
    },

    sendCommunicationTestEmail(
      params: { to: string; role: UserRole } & (
        | { communicationItem: CommunicationItem }
        | { communicationItemId: number }
      ),
    ) {
      if ('communicationItem' in params) {
        return postJson(`${baseUrl}/communications/test-emails`, params);
      }
      const { to, role } = params;
      return postJson(
        `${baseUrl}/communications/${params.communicationItemId}/test-emails`,
        { to, role },
      );
    },

    unsubscribeCommunicationEmail(params: { data: string; sign: string }) {
      return postJson(`${baseUrl}/communications/unsubscribe`, params);
    },

    updateCommunicationItem({
      changes,
      communicationItemId,
    }: {
      changes: CommunicationItemPartial;
      communicationItemId: number;
    }) {
      return patchJson(
        `${baseUrl}/communications/${communicationItemId}`,
        changes,
      );
    },
  };
}
