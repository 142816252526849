import { useMemo } from 'react';
import { CommunicationType } from "../../communicationTypes";
/**
 * Custom hook to generate initial values for a communication form.
 *
 * @param params - The parameters object.
 * @param params.communicationItem - The communication item to initialize the
 *   form with. If provided, the form will be initialized with the item's
 *   values. Otherwise, the form will be initialized with default values.
 * @param params.initialProducts - The initial list of products. Empty by
 *   default.
 * @param params.initialRoles - The initial list of user roles. Empty by
 *   default.
 * @returns The initial values for the communication form.
 */
export function useCommunicationInitialValues(_ref) {
  let {
    communicationItem,
    initialProducts,
    initialRoles
  } = _ref;
  return useMemo(() => {
    if (communicationItem) {
      const {
        type,
        ...itemFields
      } = communicationItem;
      return {
        ...itemFields,
        date: new Date(communicationItem.date),
        isReleaseNotes: type === CommunicationType.ReleaseNotes
      };
    } else {
      return {
        date: new Date(),
        message: '',
        products: initialProducts ?? [],
        roles: initialRoles ?? [],
        isReleaseNotes: false
      };
    }
  }, [communicationItem, initialProducts, initialRoles]);
}