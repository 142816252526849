import { UserRole } from 'legoland-sdk/dist/experimental';

/**
 * Communication email recipients. This is available even if the email has not
 * been sent yet.
 */
export type CommunicationEmailRecipients = {
  /** Number of users who should receive the email. */
  totalElements: number;
};

/** Communication email status. */
export type CommunicationEmailStatus = {
  /** Number of emails that have been successfully sent. */
  sent: number;

  /** Total number of emails to be sent. */
  totalElements: number;

  /** Number of emails that are waiting in the queue to be sent. */
  waiting: number;
};

/** Communication item */
export type CommunicationItem = {
  /**
   * Date when the communication was created. This is not necessarily the date
   * when the communication was displayed to users.
   *
   * @see CommunicationItem['date']
   */
  createdAt: string;

  /** Email of the user who created the communication. */
  createdBy: string;

  /**
   * Displayed date of the communication. This is not necessarily the date when
   * the communication was created.
   *
   * @see CommunicationItem['createdAt']
   */
  date: string;

  /**
   * Email status of the communication. This is only available if the
   * communication email was sent to users.
   */
  emails?: CommunicationEmailStatus;

  /** Communication id. */
  id?: number;

  /** Date when the communication was last modified. */
  lastModifiedAt: string;

  /** Email of the user who last modified the communication. */
  lastModifiedBy: string;

  /** Message of the communication, formatted as markdown. */
  message: string;

  /** Products that the communication is related to. */
  products: string[];

  /** Roles that the communication is related to. */
  roles: UserRole[];

  /** Type of the communication. */
  type: CommunicationType;
};

/** Partial communication item. This is used when updating a communication item. */
export type CommunicationItemPartial = Partial<CommunicationItem>;

/** Query for fetching communications. */
export type CommunicationsQuery = {
  /** Query filters. */
  filters?: CommunicationsQueryFilters;
};

/** Type of communication. */
export enum CommunicationType {
  /** Communication that is sent to users via email. */
  EmailOnly = 'EMAIL_ONLY',

  /**
   * Communication that is displayed to users in the application. Can also be
   * sent via email.
   */
  ReleaseNotes = 'RELEASE_NOTES',
}

/** Query filters for fetching communications. */
export type CommunicationsQueryFilters = {
  /**
   * Products that the communications are related to. If not provided,
   * communications for all products are fetched.
   */
  products?: string[];

  /**
   * Roles that the communications are related to. If not provided,
   * communications for all roles are fetched.
   */
  roles?: UserRole[];

  /**
   * Types of the communications. If not provided, communications of all types
   * are fetched.
   */
  types?: CommunicationType[];
};
