import { useEffect } from 'react';

/** Props for the ZendeskWidget component. */

/**
 * Executes a command on the Zendesk widget if it is loaded.
 *
 * This function checks if the Zendesk widget (`zE`) is available on the
 * `window` object and if it is a function. If so, it invokes the widget with
 * the provided arguments. If the widget is not loaded, it logs an error message
 * to the console.
 *
 * @param args - The arguments to pass to the Zendesk widget command.
 * @returns The result of the Zendesk widget command, or `undefined` if the
 *   widget is not loaded.
 */
export function zendeskCmd() {
  if ('zE' in window && typeof window.zE === 'function') {
    return window.zE(...arguments);
  } else {
    console.error('Zendesk widget is not loaded');
  }
}

/**
 * ZendeskWidget component that handles the insertion and display of the Zendesk
 * widget script.
 *
 * ```tsx
 * <ZendeskWidget
 *   zendeskKey="your-zendesk-key"
 *   onLoad={() => console.log('Zendesk widget loaded')}
 * />;
 * ```
 */
export const ZendeskWidget = _ref => {
  let {
    zendeskKey,
    onLoad
  } = _ref;
  useEffect(() => {
    if (!isZendeskWidgetScriptInserted()) {
      insertZendeskWidgetScript(zendeskKey, onLoad);
    } else {
      zendeskCmd('webWidget', 'show');
    }
    return () => {
      zendeskCmd('webWidget', 'hide');
    };
  }, []);
  return null;
};
function isZendeskWidgetScriptInserted() {
  return !!document.getElementById('ze-snippet');
}
function insertZendeskWidgetScript(key, onLoad) {
  const script = document.createElement('script');
  script.id = 'ze-snippet';
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
  if (onLoad) script.addEventListener('load', onLoad);
  document.body.appendChild(script);
}